import axios from 'axios'
import path from './path'

export const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

function init(app) {
  instance.interceptors.request.use(
    (config) => {
      return config
    },
    (error) => {
      console.error('> REQUEST ERROR', error, error?.response?.data)
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      return response.data
    },
    (error) => {
      console.error('> RESPONSE ERROR', error, error?.response)
      if (error?.response?.status === 401) {
        console.log('>> route', JSON.stringify(app.route))

        if (app.route.query.signature) {
          return error.response.data || error
        }

        if (app.route.query.role === 'teacher') {
          app.auth.logout()
          window.location = '/sign-in/school?role=teacher&redirect=' + app.route.path
          return
        }

        app.auth.logout()
        window.location = '/sign-in/family'
      }
      return Promise.reject(error?.response?.data || error)
    }
  )
}

function setHeader(key, value) {
  instance.defaults.headers[key] = value
}

export default {
  init,
  instance,
  setHeader,
  path,
  get: instance.get,
  post: instance.post,
  put: instance.put,
  patch: instance.patch,
  delete: instance.delete,
}
